import { TableType } from '@app/pages/cmms/types';
import { iconEnum } from '@app/configs/model/IconEnum';
import { IViewPageEntity } from '@app/pages/views/interfaces/view-page-entity.interface';
import { CmmsPages } from '@app/pages/cmms/state/cmms.reducer';

export const SELECTABLE_ENTITY_TYPES: IViewPageEntity[] = [
	// TODO Somehow this should be connected to cmms existing entities (probably -\_(-_-)_/-)
	{
		type: TableType.WORK_ORDER,
		label: 'Work Orders',
		icon: iconEnum.cmms,
		quickFilters: [
			{
				columnName: 'labels',
				name: 'Labels',
				type: 'multiselect',
				hideCloseButton: true,
			},
		],
		activePage: CmmsPages.wo,
	},
	{
		type: TableType.INCIDENTS,
		label: 'Incidents',
		icon: iconEnum.incident,
		quickFilters: [
			{
				columnName: 'labels',
				name: 'Labels',
				type: 'multiselect',
				hideCloseButton: true,
			},
		],
		activePage: CmmsPages.incidents,
	},
	{
		type: TableType.REQUESTS,
		label: 'Requests',
		icon: iconEnum.request,
		quickFilters: [
			{
				columnName: 'labels',
				name: 'Labels',
				type: 'multiselect',
				hideCloseButton: true,
			},
		],
		activePage: CmmsPages.requests,
	},
	{
		type: TableType.FILES,
		label: 'Files',
		icon: iconEnum.file,
		quickFilters: [
			{
				columnName: 'labels',
				name: 'Labels',
				type: 'multiselect',
				hideCloseButton: true,
			},
		],
		activePage: CmmsPages.files,
	},
	{
		type: TableType.ASSETS,
		label: 'Assets',
		icon: iconEnum.asset,
		quickFilters: [
			{
				columnName: 'labels',
				name: 'Labels',
				type: 'multiselect',
				hideCloseButton: true,
			},
		],
		activePage: CmmsPages.assets,
	},
];
