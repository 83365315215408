import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ViewsActions } from '@app/pages/views/state/views.actions';
import { IViewsFolder } from '@app/pages/views/interfaces/views-folder.interface';
import { AuUtilsFunctions } from '@app/shared';
import { IViewPageEntity } from '@app/pages/views/interfaces/view-page-entity.interface';
import { ICMMSTableConfig } from '@app/pages/cmms/interfaces/cmms-table.interfaces';
import { ICMMSColumn } from '@shared/ui-components/au-table-layout/utils/interfaces';
import { IWorkOrder } from '@app/pages/cmms/interfaces/work-order.interface';
import { IIncident } from '@app/pages/cmms/interfaces/incidents.interface';
import { IRequest } from '@app/pages/cmms/interfaces/request.interfaces';
import { IFileEntity } from '@app/pages/cmms/interfaces/files.interfaces';
import { ICmmsAsset } from '@app/pages/cmms/interfaces/assets.interfaces';
import { IViewEntityTablePaginationInfo } from '@app/pages/views/interfaces/entity-types.interface';
import { IAuPaginationData } from '@shared/ui-components/au-paginator/pagination-page.info';
import { IEntityView } from '@app/pages/views/interfaces/entity-view.interface';
import { SELECTABLE_ENTITY_TYPES } from '@app/pages/views/configs/selectable-entity-types.config';
import { IVisibilityColumn } from '@app/pages/views/components/views-columns-management/views-columns-management.component';

export type ViewEntityDataType =
	| IWorkOrder
	| IIncident
	| IRequest
	| IFileEntity
	| ICmmsAsset;

export interface ViewsState {
	currentBuildingId: string | null;
	isLoadingFolders: boolean;
	isCreatingFolder: boolean;
	folders: IViewsFolder[];
	isEditingFolder: boolean;
	editFolderPk: string | null;
	activeFolderPk: string | null;
	selectedCreateViewEntity: IViewPageEntity | null;
	selectedUpdateViewEntity: IViewPageEntity | null;
	viewEntity: IViewPageEntity | null;
	createViewEntityTableConfig: ICMMSTableConfig | null;
	folderViewEntityTableConfig: ICMMSTableConfig | null;
	viewEntityColumnsConfig: ICMMSColumn[] | null;
	visibleColumns: ICMMSColumn[];
	isLoadingViewTableData: boolean;
	viewEntityTableData: ViewEntityDataType[];
	viewEntityTablePagination: IViewEntityTablePaginationInfo | null;
	viewEntityTableAuPagination: IAuPaginationData | null;
	currentView: IEntityView | null;
	isSettingUpInitialViewFilters: boolean;
	isEditingView: boolean;
	isEditViewFirstLoad: boolean;
}

export const initialState: ViewsState = {
	currentBuildingId: null,
	isLoadingFolders: false,
	isCreatingFolder: false,
	folders: [],
	isEditingFolder: false,
	editFolderPk: null,
	activeFolderPk: null,
	selectedCreateViewEntity: null,
	selectedUpdateViewEntity: null,
	viewEntity: null,
	createViewEntityTableConfig: null,
	folderViewEntityTableConfig: null,
	viewEntityColumnsConfig: null,
	visibleColumns: [],
	isLoadingViewTableData: false,
	viewEntityTableData: [],
	viewEntityTablePagination: null,
	viewEntityTableAuPagination: {
		first: 50,
	},
	currentView: null,
	isSettingUpInitialViewFilters: true,
	isEditingView: false,
	isEditViewFirstLoad: true,
};

export const viewsFeature = createFeature({
	name: 'views',
	reducer: createReducer(
		initialState,
		on(ViewsActions.setCurrentBuildingId, (state, { buildingId }) => ({
			...state,
			currentBuildingId: buildingId,
		})),
		on(ViewsActions.loadFolders, ViewsActions.loadFoldersForBuilding, state => ({
			...state,
			isLoadingFolders: true,
		})),
		on(ViewsActions.loadFoldersForBuilding, (state, { buildingId }) => ({
			...state,
			currentBuildingId: buildingId,
		})),
		on(
			ViewsActions.loadFoldersSuccess,
			ViewsActions.loadBuildingViewFoldersSuccess,
			(state, { folders }) => ({
				...state,
				folders,
				isLoadingFolders: false,
			})
		),
		on(ViewsActions.clearExploringSectionMenuItems, state => ({
			...state,
			exploringSectionMenuItems: [],
		})),
		on(
			ViewsActions.loadFoldersFailure,
			ViewsActions.loadBuildingViewFoldersFailure,
			state => ({
				...state,
				isLoadingFolders: false,
			})
		),
		on(ViewsActions.showFolderCreationForm, (state, props) => ({
			...state,
			isCreatingFolder: props.state,
		})),
		on(ViewsActions.createViewFolderSuccess, (state, { folder }) => ({
			...state,
			folders: [...state.folders, folder].sort((a, b) =>
				AuUtilsFunctions.naturalSort(a.name, b.name)
			),
			isCreatingFolder: false,
		})),
		on(ViewsActions.deleteViewSuccess, (state, { view }) => {
			const currentView = state.currentView?.pk === view?.pk ? null : state.currentView;
			const clonedFolders = structuredClone(state.folders);

			return {
				...state,
				currentView,
				folders: clonedFolders.map(folder => {
					if (folder.pk === view.viewFolder.pk) {
						folder.uiTableViewConfigs = folder.uiTableViewConfigs.filter(
							tableViewConfig => {
								return tableViewConfig.pk !== view.pk;
							}
						);
					}

					return folder;
				}),
			};
		}),
		on(ViewsActions.showFolderEditForm, (state, { folderPk }) => ({
			...state,
			isEditingFolder: true,
			editFolderPk: folderPk,
		})),
		on(ViewsActions.cancelFolderUpdate, state => ({
			...state,
			isEditingFolder: false,
			editFolderPk: null,
		})),
		on(ViewsActions.updateViewFolderSuccess, (state, { folder }) => ({
			...state,
			folders: state.folders
				.map(f => (f.pk === folder.pk ? folder : f))
				.sort((a, b) => AuUtilsFunctions.naturalSort(a.name, b.name)),
			isEditingFolder: false,
			editFolderPk: null,
		})),
		on(ViewsActions.deleteViewFoldersSuccess, (state, { folderPks }) => ({
			...state,
			folders: state.folders.filter(f => !folderPks.includes(f.pk)),
		})),
		on(ViewsActions.createViewInsideFolderInit, (state, { folderPk }) => ({
			...state,
			activeFolderPk: folderPk,
			isEditingView: false, // Dunno but in some cases it can be true...
		})),
		on(ViewsActions.createViewSelectEntityType, (state, { entity }) => ({
			...state,
			selectedCreateViewEntity: entity,
			viewEntity: entity,
		})),
		on(
			ViewsActions.createViewCancelEntityTypeSelection,
			ViewsActions.cancelCreateView,
			state => ({
				...state,
				selectedCreateViewEntity: null,
				selectedUpdateViewEntity: null,
				viewEntity: null,
				createViewEntityTableConfig: null,
				viewEntityColumnsConfig: null,
				isLoadingViewTableData: false,
				viewEntityTableData: [],
				viewEntityTablePagination: null,
				viewEntityTableAuPagination: initialState.viewEntityTableAuPagination,
				visibleColumns: [],
			})
		),
		on(ViewsActions.cancelCreateView, state => ({
			...state,
			exploringSectionMenuItems: [],
		})),
		on(ViewsActions.loadCreateViewEntityTableConfigSuccess, (state, { tableConfig }) => ({
			...state,
			createViewEntityTableConfig: tableConfig,
			viewEntityColumnsConfig: tableConfig.columns,
		})),
		on(ViewsActions.loadViewEntityTableData, (state, { variables }) => ({
			...state,
			isLoadingViewTableData: true,
			viewEntityTableAuPagination: {
				first: variables?.first,
				last: variables?.last,
				after: variables?.after,
				before: variables?.before,
			},
		})),
		on(ViewsActions.loadViewEntityTableDataSuccess, (state, { data, pagination }) => ({
			...state,
			viewEntityTableData: data,
			viewEntityTablePagination: pagination,
			isLoadingViewTableData: false,
		})),
		on(ViewsActions.setVisibleColumns, (state, { columns }) => ({
			...state,
			visibleColumns: columns,
		})),
		on(ViewsActions.clearVisibleColumns, state => ({
			...state,
			visibleColumns: [],
		})),
		on(ViewsActions.createViewSuccess, (state, { view }) => ({
			...state,
			folders: state.folders.map(folder => {
				const viewObj = {
					pk: view.pk,
					name: view.name,
				};
				return folder.pk === view.viewFolder.pk
					? { ...folder, uiTableViewConfigs: [...folder.uiTableViewConfigs, viewObj] }
					: folder;
			}),
			currentView: view,
		})),
		on(ViewsActions.loadView, (state, { folderPk }) => ({
			...state,
			activeFolderPk: folderPk, // needed for menu section items
			// Finish this
			isLoadingViewTableData: true,
		})),
		on(ViewsActions.loadViewSuccess, (state, { view }) => ({
			...state,
			currentView: view,
			//Finish this
			viewEntity: SELECTABLE_ENTITY_TYPES.find(
				entity => entity.type === view.uiTable.tableType
			),
			visibleColumns: JSON.parse(view.columnConfigs),
		})),
		on(ViewsActions.clearLoadedView, state => ({
			...state,
			currentView: null,
		})),
		on(ViewsActions.clearExploringSectionMenuItems, state => ({
			...state,
			exploringSectionMenuItems: [],
		})),
		on(ViewsActions.loadViewEntityTableConfigSuccess, (state, { tableConfig }) => ({
			...state,
			folderViewEntityTableConfig: tableConfig,
			viewEntityColumnsConfig: tableConfig.columns,
		})),
		on(ViewsActions.clearViewTableConfig, state => ({
			...state,
			folderViewEntityTableConfig: null,
			viewEntityColumnsConfig: null,
		})),
		on(ViewsActions.viewTableInitialFiltersSetupCompleted, state => ({
			...state,
			isSettingUpInitialViewFilters: false,
		})),
		on(ViewsActions.viewTableInitialFiltersSetupClear, state => ({
			...state,
			isSettingUpInitialViewFilters: true,
		})),
		on(ViewsActions.startViewUpdate, state => ({
			...state,
			isEditingView: true,
			selectedUpdateViewEntity: { ...state.viewEntity },
			//...
		})),
		on(ViewsActions.cancelViewUpdate, state => ({
			...state,
			isEditingView: false,
			selectedUpdateViewEntity: null,
			isEditViewFirstLoad: true,
			//...
		})),
		on(ViewsActions.updateViewSuccess, (state, { view }) => ({
			...state,
			currentView: view,
			isEditingView: false,
			viewEntity: SELECTABLE_ENTITY_TYPES.find(
				(entity: IViewPageEntity) => entity.type === view.uiTable.tableType
			),
			visibleColumns: JSON.parse(view.columnConfigs),
			selectedUpdateViewEntity: null,
			folders: state.folders.map((folder: IViewsFolder) => {
				if (folder.pk === view.viewFolder.pk) {
					// Filter out the view if it already exists to avoid duplication
					const filteredConfigs = folder.uiTableViewConfigs.filter(
						config => config.pk !== view.pk
					);

					// Add the new view configuration
					return {
						...folder,
						uiTableViewConfigs: [...filteredConfigs, { pk: view.pk, name: view.name }],
					};
				}
				return folder;
			}),
		})),
		on(ViewsActions.updateViewChangeEntityType, (state, { entity }) => ({
			...state,
			selectedUpdateViewEntity: entity,
			viewEntity: entity,
			isEditViewFirstLoad: false,
		}))
	),
	// If more selectors needed use extraSelectors https://ngrx.io/guide/store/feature-creators
	extraSelectors: ({ selectViewsState }) => {
		const selectEditFolderData = createSelector(selectViewsState, state => {
			const folderPk = state.editFolderPk;
			if (!folderPk) {
				return null;
			}
			return state.folders.find(folder => folder.pk === folderPk);
		});
		const selectActiveFolder = createSelector(selectViewsState, state => {
			const folderPk = state.activeFolderPk;
			if (!folderPk) {
				return null;
			}
			return state.folders.find(folder => folder.pk === folderPk);
		});
		const isCreatingView = createSelector(
			selectViewsState,
			state => !!state.selectedCreateViewEntity
		);
		const selectVisibleColumnsForTable = createSelector(selectViewsState, state => {
			return state.visibleColumns?.filter((column: IVisibilityColumn) => column.visible);
		});
		return {
			selectEditFolderData,
			selectActiveFolder,
			isCreatingView,
			selectVisibleColumnsForTable,
		};
	},
});

export const {
	name: viewsFeatureKey,
	reducer: viewsReducer,
	selectViewsState,
	selectCurrentBuildingId,
	selectIsLoadingFolders,
	selectIsCreatingFolder,
	selectFolders,
	selectIsEditingFolder,
	selectEditFolderPk,
	selectEditFolderData,
	selectActiveFolder,
	selectSelectedCreateViewEntity,
	selectViewEntity,
	selectCreateViewEntityTableConfig,
	selectViewEntityColumnsConfig,
	selectIsLoadingViewTableData,
	selectViewEntityTableData,
	isCreatingView,
	selectViewEntityTablePagination,
	selectViewEntityTableAuPagination,
	selectVisibleColumns,
	selectVisibleColumnsForTable,
	selectCurrentView,
	selectFolderViewEntityTableConfig,
	selectIsSettingUpInitialViewFilters,
	selectIsEditingView,
	selectSelectedUpdateViewEntity,
	selectIsEditViewFirstLoad,
} = viewsFeature;
