import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AuGqlError } from '@app/shared';
import { IViewsFolder } from '@app/pages/views/interfaces/views-folder.interface';
import { IAuNavbarConfig } from '@app/configs/au-navbar.config';
import { IViewPageEntity } from '@app/pages/views/interfaces/view-page-entity.interface';
import { ICMMSTableConfig } from '@app/pages/cmms/interfaces/cmms-table.interfaces';
import { TableType } from '@app/pages/cmms/types';
import { ViewEntityDataType } from '@app/pages/views/state/views.reducer';
import { IViewEntityTablePaginationInfo } from '@app/pages/views/interfaces/entity-types.interface';
import { ICMMSColumn } from '@shared/ui-components/au-table-layout/utils/interfaces';
import {
	ICreateViewVariables,
	IEntityView,
	IUpdateViewVariables,
} from '@app/pages/views/interfaces/entity-view.interface';
import type { LocationInput } from '@app/pages/cmms/interfaces/cmms-global.interfaces';

/** https://ngrx.io/api/store/createActionGroup */
export const ViewsActions = createActionGroup({
	source: 'Views',
	events: {
		'Load Folders': emptyProps(),
		'Load Folders Success': props<{ folders: IViewsFolder[] }>(),
		'Load Folders Failure': props<{ error?: AuGqlError }>(),
		'Load Folders For Building': props<{ buildingId: string }>(),
		'Load Building View Folders Success': props<{ folders: IViewsFolder[] }>(),
		'Load Building View Folders Failure': props<{ error?: AuGqlError }>(),
		'Set Exploring Section Menu Items': props<{ menuItems: IAuNavbarConfig[] }>(),
		'Clear Exploring Section Menu Items': emptyProps(),
		'Set Current Building Id': props<{ buildingId: string | null }>(),
		'Show Folder Creation Form': props<{ state: boolean }>(),
		'Create View Folder': props<{
			buildingId: string | null;
			name: string;
			description?: string;
		}>(),
		'Create View Folder Success': props<{ folder: IViewsFolder }>(),
		'Create View Folder Failure': props<{ error?: AuGqlError }>(),
		'Show Folder Edit Form': props<{ folderPk: string }>(),
		'Update View Folder': props<{
			pk: string;
			name: string;
			description?: string;
		}>(),
		'Update View Folder Success': props<{ folder: IViewsFolder }>(),
		'Update View Folder Failure': props<{ error?: AuGqlError }>(),
		'Cancel Folder Update': emptyProps(),
		'Delete View Folders': props<{ folderPks: string[] }>(),
		'Delete View Folders Success': props<{ folderPks: string[] }>(),
		'Delete View Folders Failure': props<{ error?: AuGqlError }>(),
		'Create View Inside Folder From View': emptyProps(),
		'Create View Inside Folder': props<{ folderPk: string }>(),
		'Create View Inside Folder Init': props<{ folderPk: string }>(),
		'Delete View': props<{ view: IEntityView }>(),
		'Delete View Success': props<{ view: IEntityView }>(),
		'Delete View Failure': props<{ error?: AuGqlError }>(),
		'Create Exploring Menu Section Items': emptyProps(),
		'Create View Select Entity Type': props<{ entity: IViewPageEntity }>(),
		'Create View Cancel Entity Type Selection': emptyProps(),
		'Cancel Create View': emptyProps(),
		'Load Create View Entity Table Config Failure': props<{ error?: AuGqlError }>(),
		'Load Create View Entity Table Config Success': props<{
			tableConfig: ICMMSTableConfig;
		}>(),
		//TODO Think later whats the best way to provide type for variables here and all other places,
		// it should be possible, just don't have time to do that. Types for variables already exist like:
		// ICmmsWorkOrderQueryVariables etc..
		'Load View Entity Table Data': props<{ entityType: TableType; variables: any }>(),
		'Load View Entity Table Data Success': props<{
			data: ViewEntityDataType[];
			pagination: IViewEntityTablePaginationInfo;
		}>(),
		'Load View Entity Table Data Failure': props<{ error?: AuGqlError }>(),
		'Set Visible Columns': props<{ columns: ICMMSColumn[] }>(),
		'Clear Visible Columns': emptyProps(),
		'Create View': props<{ variables: ICreateViewVariables }>(),
		'Create View Success': props<{ view: IEntityView }>(),
		'Create View Failure': props<{ error?: AuGqlError }>(),
		'Load View': props<{ viewPk: string; folderPk: string }>(),
		'Load View Success': props<{ view: IEntityView }>(),
		'Load View Failure': props<{ error?: AuGqlError }>(),
		'Load View Entity Table Config': props<{ tableType: TableType }>(),
		'Load View Entity Table Config Success': props<{ tableConfig: ICMMSTableConfig }>(),
		'Load View Entity Table Config Failure': props<{ error?: AuGqlError }>(),
		'Clear loaded View': emptyProps(),
		'Clear View Table Config': emptyProps(),
		'View Table Initial Filters Setup Completed': emptyProps(),
		'View Table Initial Filters Setup Clear': emptyProps(),
		'Start View Update': emptyProps(),
		'Cancel View Update': props<{ viewPk: string; folderPk: string }>(),
		'Update View': props<{ variables: IUpdateViewVariables }>(),
		'Update View Change Entity Type': props<{ entity: IViewPageEntity }>(),
		'Update View Success': props<{ view: IEntityView }>(),
		'Update View Failure': props<{ error?: AuGqlError }>(),
		'Open File uploader': props<{ locations: LocationInput[] }>(),
		'File uploader entity change': props<{ eventType: 'loaded' | 'deleted' }>(),
	},
});
